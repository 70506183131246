import { render, staticRenderFns } from "./InvoiceCategoryIndex.vue?vue&type=template&id=02044724&scoped=true&"
import script from "./InvoiceCategoryIndex.vue?vue&type=script&lang=js&"
export * from "./InvoiceCategoryIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02044724",
  null
  
)

export default component.exports